<template>
  <div>
    <div class="content-box">
      <div class="container">
        <div class="row">
          <div class="col-xl-10 col-lg-12 col-md-12 mx-auto">
            <div class="inner-box" v-html="html">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      html: "",
    };
  },
  mounted() {
    if (!this.$route.params.data) {
      return this.$router.push("/");
    }
    this.html = this.$route.params.data;
  },
};
</script>


<style scoped>
.content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.inner-box {
  border: 2px solid #000;
  padding: 10px 10px;
}
</style>